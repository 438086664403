
import Vue from "vue";

export default Vue.extend({
  name: "ReportPasswordErrorDialog",
  props: {
    dialog: Boolean,
    text: String,
  },
  methods: {
    async onConfirmation() {
      this.$emit("onConfirmation");
      this.$emit("update:dialog", false);
    },
  },
});
